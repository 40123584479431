import { colorPalette } from "gx-npm-common-styles";

const colorCoal = colorPalette.neutrals.coal.hex;
const colorQuartz = colorPalette.neutrals.quartz.hex;

const notEntitledStyles = {
  productDescription: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    marginBottom: "40px",
  },
  noFeatures: {
    display: "flex",
    flexDirection: "column" as const,
    paddingTop: "32px",
    backgroundColor: colorQuartz,
    borderRadius: "8px",
  },
  noFeaturesDesc: {
    display: "flex",
    alignItems: "start",
    marginLeft: "32px",
    marginBottom: "28px",
    gap: "12px",
  },
  title: {
    width: "600px",
    color: colorCoal,
  },
};

export { notEntitledStyles };
