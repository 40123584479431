import React from "react";
import { createGenerateClassName, StylesProvider } from "@material-ui/core/styles";
import { messengerFlagLoader } from "gx-npm-lib";
import { FeatureFlagProvider } from "gx-npm-ui";
import App from "./app.component";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const generateClassName = createGenerateClassName({
  seed: "Product-Overview-App",
});
const Root = () => {
  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <StylesProvider generateClassName={generateClassName}>
        <BrowserRouter>
          <Routes>
            <Route path={"/s/market/:templateId/product/:productId"} element={<App />} />
            <Route path={"/s/market/:templateId/product/:productId/template-preview"} element={<App />} />
            <Route path={"/s/market/:templateId/product/:productId/preview"} element={<App />} />
            <Route path={"/s/evaluation/:initiativeId/product/:initProdId/profile"} element={<App />} />
          </Routes>
        </BrowserRouter>
      </StylesProvider>
    </FeatureFlagProvider>
  );
};

export default Root;
