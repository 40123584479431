const MedalIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.0261 23.2511C21.2173 23.5844 20.9767 23.9999 20.5924 23.9999L13.7524 23.9999C13.5694 23.9999 13.401 23.9 13.3134 23.7393L7.99983 13.9999L15.72 13.9999L21.0261 23.2511Z"
        fill="#005C81"
      />
      <path
        d="M3.42945 23.2511C3.23827 23.5844 3.47891 23.9999 3.86318 23.9999L10.7032 23.9999C10.8862 23.9999 11.0545 23.9 11.1421 23.7393L16.4557 13.9999L8.73552 13.9999L3.42945 23.2511Z"
        fill="#007BAC"
      />
      <path
        d="M12 0L12.8937 0.496838L13.8712 0.196671L14.6421 0.868467L15.6606 0.77809L16.275 1.59548L17.2901 1.71885L17.7211 2.64611L18.6883 2.97782L18.9171 3.97444L19.7942 4.5L19.8108 5.5224L20.5595 6.21885L20.3632 7.22235L20.9507 8.05924L20.55 9L20.9507 9.94076L20.3632 10.7776L20.5595 11.7812L19.8108 12.4776L19.7942 13.5L18.9171 14.0256L18.6883 15.0222L17.7211 15.3539L17.2901 16.2812L16.275 16.4045L15.6606 17.2219L14.6421 17.1315L13.8712 17.8033L12.8937 17.5032L12 18L11.1063 17.5032L10.1288 17.8033L9.3579 17.1315L8.33937 17.2219L7.725 16.4045L6.70993 16.2812L6.27893 15.3539L5.3117 15.0222L5.0829 14.0256L4.20577 13.5L4.18919 12.4776L3.44049 11.7812L3.63684 10.7776L3.0493 9.94076L3.45 9L3.0493 8.05924L3.63684 7.22235L3.44049 6.21885L4.18919 5.5224L4.20577 4.5L5.0829 3.97444L5.3117 2.97782L6.27893 2.64611L6.70993 1.71885L7.725 1.59548L8.33937 0.77809L9.3579 0.868467L10.1288 0.196671L11.1063 0.496838L12 0Z"
        fill="#FEC10D"
      />
      <circle cx="12" cy="9" r="7" fill="#FE9E0D" />
    </svg>
  );
};

export default MedalIcon;
