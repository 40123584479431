import classNames from "classnames";
import { TextLink, TypographyComponent } from "gx-npm-ui";
import { useTranslation } from "react-i18next";
import { ClientEvent } from "../../app.constants";
import { EmergingMarketQuadrantIcon, MQIcon } from "gx-npm-icons";
import { IconErrorData } from "../../icons";
import styles from "./gartner-quadrant.styles.module.scss";
import { MouseEvent, useContext } from "react";
import { ProductOverviewContext } from "../../context/product-overview.context";
import { useCaptureEventsV2, GcomDocType, GartnerQuadrant } from "gx-npm-lib";

const GartnerQuadrantsComponent = () => {
  const { t } = useTranslation();
  const qdPositions = ["Challenger", "Leader", "Niche Player", "Visionary"];

  type QDPositionText = { [key: string]: string };
  const mqQuadText: QDPositionText = {
    Challenger: t("Challengers"),
    Leader: t("Leader"),
    "Niche Player": t("Niche Player"),
    Visionary: t("Visionaries"),
  };
  const emQuadText: QDPositionText = {
    Challenger: t("Emerging Challengers"),
    Leader: t("Emerging Leader"),
    "Niche Player": t("Emerging Specialist"),
    Visionary: t("Emerging Visionary"),
  };
  const captureEvents = useCaptureEventsV2();

  const {
    gcomResearchDocType,
    initId,
    initProdId,
    isError,
    isFromProductProfile,
    productId,
    productName,
    gcomResId,
    position,
    description,
  } = useContext(ProductOverviewContext);

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    let eventType, metaData;
    if (isFromProductProfile && gcomResearchDocType === GcomDocType.EMERGING_MARKET_QUAD) {
      eventType = ClientEvent.INITIATIVE_PRODUCT_PROFILE_EMERGING_MARKET_QUADRANT_LINK_CLICKED;
      metaData = { initiativeId: initId, initProductId: initProdId, productName };
    } else if (isFromProductProfile && gcomResearchDocType === GcomDocType.MAGIC_QUAD) {
      eventType = ClientEvent.INITIATIVE_PRODUCT_PROFILE_MAGIC_QUADRANT_LINK_CLICKED;
      metaData = { initiativeId: initId, initProductId: initProdId, productName };
    } else if (!isFromProductProfile && gcomResearchDocType === GcomDocType.EMERGING_MARKET_QUAD) {
      eventType = ClientEvent.PRODUCT_PROFILE_EMERGING_MARKET_QUADRANT_LINK_CLICKED;
      metaData = { productId: productId.toString(), productName };
    } else {
      eventType = ClientEvent.PRODUCT_PROFILE_MAGIC_QUADRANT_LINK_CLICKED;
      metaData = { productId: productId.toString(), productName };
    }
    captureEvents([{ eventType, metaData }]);
    window.open(`https://www.gartner.com/document/${gcomResId}?ref=buysmartresearch`, "_blank", "noopener");
  };

  return (
    <div
      className={classNames(
        styles.root,
        gcomResearchDocType === GcomDocType.EMERGING_MARKET_QUAD && styles.emqCardAccent
      )}
    >
      <div className={styles.section}>
        {gcomResearchDocType === GcomDocType.EMERGING_MARKET_QUAD ? <EmergingMarketQuadrantIcon /> : <MQIcon />}
        <TypographyComponent
          rootClassName={styles.title}
          boldness="medium"
          color="gartnerBlue"
          element="h5"
          styling="p2"
        >
          {gcomResearchDocType === GcomDocType.EMERGING_MARKET_QUAD
            ? t("Gartner Emerging Market Quadrant")
            : t("Gartner Magic Quadrant")}
        </TypographyComponent>
      </div>

      {isError && (
        <div className={styles.errorState}>
          <IconErrorData />
          <TypographyComponent rootClassName={styles.errorText} element="p" styling={"p3"}>
            {t("Oops... we were unable to load this information.")}
          </TypographyComponent>
        </div>
      )}

      {!isError && (
        <div>
          {position === GartnerQuadrant.NONE && (
            <TypographyComponent element="p" color="coal" styling="p3">
              {gcomResearchDocType === GcomDocType.EMERGING_MARKET_QUAD
                ? t("There is no Emerging Market for this technology.")
                : t("There is no Magic Quadrant for this technology.")}
            </TypographyComponent>
          )}
          <div className={styles.qdInfo}>
            <div>
              <TypographyComponent element="p" styling="p4" rootClassName={styles.qdSubtitle}>
                {t("Position")}
              </TypographyComponent>
              {position !== GartnerQuadrant.NONE && (
                <TypographyComponent boldness="medium" color="carbon" element="p" styling="h4">
                  {gcomResearchDocType === GcomDocType.EMERGING_MARKET_QUAD
                    ? emQuadText[position]
                    : mqQuadText[position]}
                </TypographyComponent>
              )}
            </div>
            <div className={classNames(styles.qdContainer)}>
              {qdPositions.map((qdPosition) => (
                <div key={qdPosition}>
                  {qdPosition === position ? (
                    <div
                      className={classNames(
                        styles.qdBoxSelected,
                        gcomResearchDocType === GcomDocType.EMERGING_MARKET_QUAD && styles.emqBackground
                      )}
                    >
                      <TypographyComponent color={"white"} element="p" styling="p5" boldness="medium">
                        {gcomResearchDocType === GcomDocType.EMERGING_MARKET_QUAD
                          ? emQuadText[qdPosition]
                          : mqQuadText[qdPosition]}
                      </TypographyComponent>
                    </div>
                  ) : (
                    <div className={styles.noneSelectedPosition}>
                      <TypographyComponent color={"iron"} element="p" styling="p5" boldness="medium">
                        {gcomResearchDocType === GcomDocType.EMERGING_MARKET_QUAD
                          ? emQuadText[qdPosition]
                          : mqQuadText[qdPosition]}
                      </TypographyComponent>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          {description && (
            <TypographyComponent styling="p4" rootClassName={styles.description}>
              {description}
            </TypographyComponent>
          )}
          {gcomResId > 0 && (
            <TextLink
              href={`https://www.gartner.com/documents/${gcomResId}?ref=buysmartresearch`}
              target="_blank"
              onClick={handleClick}
              variant="secondary"
              text={
                gcomResearchDocType === GcomDocType.EMERGING_MARKET_QUAD
                  ? t("Learn more about this Emerging Market Quadrant")
                  : t("Learn more about this Magic Quadrant")
              }
            />
          )}
        </div>
      )}
    </div>
  );
};

export default GartnerQuadrantsComponent;
