const productOverviewStyles = {
  container: {
    paddingLeft: 26,
    paddingRight: 26,
    paddingTop: 28,
    marginBottom: 200,
    margin: "0 auto",
    maxWidth: 1400,
    transition: "padding 0.5s ease-in-out, margin 0.5s ease-in-out, width 0.5s ease-in-out",
    "@media (min-width:1240px)": {
      paddingLeft: 56,
      paddingRight: 56,

      "&.product-profile": {
        paddingTop: 48,
        maxWidth: 1508,
      },
    },
  },
  leftColumn: {
    flex: 1,
  },
  rightColumn: {
    width: 296,
    "@media (min-width:1240px)": {
      width: 348,
    },
    "@media (min-width:1440px)": {
      width: 401,
    },
  },
  marketInfo: {
    display: "flex",
    gap: 24,
  },
};

export { productOverviewStyles };
