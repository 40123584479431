import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { getAsyncRequest, isValidResponse } from "gx-npm-lib";
import {
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  GartnerFooter,
  Loader,
  SnackbarBanner,
  useFeatureFlag,
} from "gx-npm-ui";
import { Fragment, useContext, useEffect, useState } from "react";
import { DEFAULT_PRODUCT_REVIEW } from "../app.constants";
import { ProductOverviewApiResponse, ProductOverviewApiResponseV2 } from "../app.types";
import { ProductOverviewContext } from "../context/product-overview.context";
import FeaturesFunctionality from "./features-functionality/features-functionality.component";
import MagicQuadrant from "./magic-quadrant/magic-quadrant.component";
import MoreFromGartner from "./more-from-gartner/more-from-gartner.component";
import ProposalReview from "./proposal-review/proposal-review.component";
import ReviewsV2Component from "./reviews-v2/reviews-v2.component";
import { VendorAlternatives } from "./vendor-alternatives/vendor-alternatives.component";
import { productOverviewStyles as styles } from "./product-overview.styles";
import { GCOM_4321__emqUpdates } from "../lib/feature-flags";
import GartnerQuadrantsComponent from "./mq-quadrants/gartner-quadrant.component";

const useStyles = makeStyles(() => styles);
const ProductOverview = () => {
  const {
    initId,
    initProdId,
    isEligibleProductPR,
    isError,
    isFromProductProfile,
    productId,
    productName,
    templateId,
    setAlternativeProducts,
    setFeatsAndFuncs,
    setGcomCcDocCd,
    setGcomCcResId,
    setHasMq,
    setHasReview,
    setHasSearchResults,
    setImageLoc,
    setIsEligibleProductPR,
    setIsError,
    setMq,
    setProductName,
    setProductCount,
    setProductUrl,
    setReview,
    setSearchResults,
    setTeamMembersWithPRAccess,
    setTemplateName,
    setGcomResearchDocType,
    setTemplateProductCount,
    setTemplateAlternativeProducts,
    setUrl,
    setName,
    setGcomResId,
    setPosition,
    setDescription,
  } = useContext(ProductOverviewContext);
  const [isLoading, setIsLoading] = useState(true);
  const isGCOM4321FfOn = useFeatureFlag(GCOM_4321__emqUpdates);

  useEffect(() => {
    if (isGCOM4321FfOn) {
      return;
    }
    let url = "";
    if (
      (templateId && !productId) ||
      (!templateId && productId) ||
      (initId && !initProdId) ||
      (!initId && initProdId)
    ) {
      return;
    }
    if (initId && initProdId) {
      url = `api/v2/initiatives/product-overview/${initId}/${initProdId}`;
    } else if (productId && templateId) {
      url = `api/v2/data/product-overview/${templateId}/${productId}`;
    } else {
      return;
    }

    (async () => {
      setIsLoading(true);
      const response: ProductOverviewApiResponse = await getAsyncRequest(url);
      if (isValidResponse(response)) {
        const payload = response.data?.data;
        if (payload) {
          setAlternativeProducts(payload.productAlternatives || []);
          setFeatsAndFuncs(payload.featsAndFuncs || []);
          setGcomCcDocCd(payload.gcomCcDocCd || -1);
          setGcomCcResId(payload.gcomCcResId || -1);
          setHasMq(payload.hasMq || false);
          setHasReview(payload.hasReview || false);
          setHasSearchResults(payload.hasSearchResults || false);
          setImageLoc(payload.imageLoc || "");
          setIsEligibleProductPR(payload.isEligiblePR || false); // todo potentially move to a hook
          setMq(payload.mq || { docURL: "", qdDesc: "", qdPosition: "" });
          setProductCount(payload.productCount || 0);
          setProductName(payload.productName || "");
          setProductUrl(payload.productURL || "");
          setReview(payload.review || DEFAULT_PRODUCT_REVIEW);
          setSearchResults(payload.searchResults || []);
          setTeamMembersWithPRAccess(payload.teamMembersWithPRAccess || []);
          setTemplateName(payload.templateName || "");
        }
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    })();
  }, [
    initId,
    initProdId,
    isEligibleProductPR,
    templateId,
    productId,
    productName,
    setAlternativeProducts,
    setFeatsAndFuncs,
    setGcomCcDocCd,
    setGcomCcResId,
    setImageLoc,
    setIsEligibleProductPR,
    setIsError,
    setHasReview,
    setHasMq,
    setHasSearchResults,
    setMq,
    setProductName,
    setProductUrl,
    setProductCount,
    setReview,
    setSearchResults,
    setTeamMembersWithPRAccess,
    setTemplateName,
    isGCOM4321FfOn,
  ]);

  useEffect(() => {
    if (!isGCOM4321FfOn) {
      return;
    }
    let url = "";
    if (
      (templateId && !productId) ||
      (!templateId && productId) ||
      (initId && !initProdId) ||
      (!initId && initProdId)
    ) {
      return;
    }
    if (initId && initProdId) {
      url = `api/v2/initiatives/product-overview/${initId}/${initProdId}`;
    } else if (productId && templateId) {
      url = `api/v3/data/product-overview/${templateId}/${productId}`;
    } else {
      return;
    }

    (async () => {
      const response: ProductOverviewApiResponseV2 = await getAsyncRequest(url);
      if (response?.status === 200 && response.data?.data) {
        const payload = response.data?.data;
        if (payload) {
          setTemplateAlternativeProducts(payload.templateProductAlternatives || []);
          setFeatsAndFuncs(payload.featsAndFuncs || []);
          setHasReview(payload.hasReview || false);
          setHasSearchResults(payload.hasSearchResults || false);
          setImageLoc(payload.imageLoc || "");
          setIsEligibleProductPR(payload.isEligiblePR || false); // todo potentially move to a hook
          setTemplateProductCount(payload.templateProductCount || 0);
          setName(payload.name || "");
          setUrl(payload.url || "");
          setReview(payload.review || DEFAULT_PRODUCT_REVIEW);
          setSearchResults(payload.searchResults || []);
          setTeamMembersWithPRAccess(payload.teamMembersWithPRAccess || []);
          setTemplateName(payload.templateName || "");
          setGcomResearchDocType(payload.gcomResearchDocType || "");
          if (payload.magicQuadDoc?.gcomMagicQuadResId > 0) {
            setGcomResId(payload.magicQuadDoc.gcomMagicQuadResId || -1);
            setDescription(payload.magicQuadDoc.description || "");
            setPosition(payload.magicQuadDoc.position || "");
          }
          if (payload.emergingMarketQuadDoc?.gcomEMarketQuadResId > 0) {
            setGcomResId(payload.emergingMarketQuadDoc.gcomEMarketQuadResId);
            setDescription(payload.emergingMarketQuadDoc.description || "");
            setPosition(payload.emergingMarketQuadDoc.position || "");
          }
          if (payload.marketGuideDoc?.gcomMarketGuideResId > 0) {
            setGcomResId(payload.marketGuideDoc.gcomMarketGuideResId);
          }
        }
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    })();
  }, [
    initId,
    initProdId,
    isGCOM4321FfOn,
    productId,
    setDescription,
    setFeatsAndFuncs,
    setGcomResId,
    setGcomResearchDocType,
    setHasReview,
    setHasSearchResults,
    setImageLoc,
    setIsEligibleProductPR,
    setIsError,
    setName,
    setPosition,
    setReview,
    setSearchResults,
    setTeamMembersWithPRAccess,
    setTemplateAlternativeProducts,
    setTemplateName,
    setTemplateProductCount,
    setUrl,
    templateId,
  ]);

  const classes = useStyles();
  return (
    <Fragment>
      {isLoading && <Loader />}
      {!isLoading && (
        <Fragment>
          <div className={classNames(classes.container, isFromProductProfile && "product-profile")}>
            <div className={classes.marketInfo}>
              <div className={classes.leftColumn}>
                <FeaturesFunctionality />
                <ReviewsV2Component />
                <MoreFromGartner />
              </div>
              <div className={classes.rightColumn}>
                <FeatureFlagBooleanContainer flagName={GCOM_4321__emqUpdates}>
                  <FeatureFlagBooleanOff>
                    <MagicQuadrant />
                  </FeatureFlagBooleanOff>
                  <FeatureFlagBooleanOn>
                    <GartnerQuadrantsComponent />
                  </FeatureFlagBooleanOn>
                </FeatureFlagBooleanContainer>
                {isEligibleProductPR && <ProposalReview />}
                <VendorAlternatives />
              </div>
            </div>
          </div>
          <GartnerFooter />
        </Fragment>
      )}
      <SnackbarBanner isOpen={isError} setIsOpen={setIsError} type="ERROR" isDefaultErrorMessage={true} />
    </Fragment>
  );
};

export default ProductOverview;
