export const getRankText = (rank: number): string => {
  if (rank >= 11 && rank <= 13) {
    return "th";
  }
  const lastDigit = rank % 10;
  if (lastDigit === 1) {
    return "st";
  } else if (lastDigit === 2) {
    return "nd";
  } else if (lastDigit === 3) {
    return "rd";
  } else {
    return "th";
  }
};
