// TODO all icons should be stored in gx-npm-icons so design can validate through storybook
import React from "react";
import { colorPalette } from "gx-npm-common-styles";

const stone = colorPalette.neutrals.stone.hex;
const gartnerBlue = colorPalette.brand.gartnerBlue.hex;
const lightPear = colorPalette.status.lightPear.hex;
const darkPear = colorPalette.status.darkPear.hex;
const lightCherry = colorPalette.status.lightCherry.hex;
const poisonCherry = colorPalette.status.poisonCherry.hex;

const xmlnsUrl = "http://www.w3.org/2000/svg";
const IconEmptyStar = ({ fill = "none", height = "18", width = "20", pathFill = stone, viewBox = "0 0 20 18" }) => {
  return (
    <svg fill={fill} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      <path
        d="M9.80113 14.8451L14.3689 17.5654C15.2054 18.064 16.229 17.327 16.0089 16.3949L14.7981 11.2795L18.8376 7.83305C19.575 7.20445 19.1788 6.01229 18.2102 5.93643L12.894 5.49207L10.8137 0.658399C10.4395 -0.219466 9.16275 -0.219466 8.78852 0.658399L6.70827 5.48124L1.39207 5.92559C0.42349 6.00145 0.0272517 7.19361 0.764695 7.82221L4.80413 11.2686L3.5934 16.3841C3.37326 17.3162 4.39688 18.0531 5.23338 17.5546L9.80113 14.8451Z"
        fill={pathFill}
      />
    </svg>
  );
};

const IconFullStar = ({
  fill = "none",
  height = "18",
  width = "20",
  pathFill = gartnerBlue,
  viewBox = "0 0 20 18",
}) => {
  return (
    <svg fill={fill} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      <path
        d="M9.83043 14.8451L14.3982 17.5654C15.2347 18.064 16.2583 17.327 16.0382 16.3949L14.8274 11.2795L18.8669 7.83305C19.6043 7.20445 19.2081 6.01229 18.2395 5.93643L12.9233 5.49207L10.843 0.658399C10.4688 -0.219466 9.19204 -0.219466 8.81782 0.658399L6.73757 5.48124L1.42137 5.92559C0.452787 6.00145 0.0565486 7.19361 0.793992 7.82221L4.83342 11.2686L3.62269 16.3841C3.40256 17.3162 4.42618 18.0531 5.26268 17.5546L9.83043 14.8451Z"
        fill={pathFill}
      />
    </svg>
  );
};

const IconHalfStar = ({
  fill = "none",
  height = "18",
  width = "20",
  pathFill = gartnerBlue,
  viewBox = "0 0 20 18",
}) => {
  return (
    <svg fill={fill} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      <path
        d="M10.3021 14.8451L14.8699 17.5654C15.7064 18.064 16.73 17.327 16.5098 16.3949L15.2991 11.2795L19.3385 7.83305C20.076 7.20445 19.6797 6.01229 18.7112 5.93643L13.395 5.49207L11.3147 0.658399C10.9405 -0.219466 9.66372 -0.219466 9.2895 0.658399L7.20925 5.48124L1.89305 5.92559C0.924467 6.00145 0.528228 7.19361 1.26567 7.82221L5.3051 11.2686L4.09437 16.3841C3.87424 17.3162 4.89786 18.0531 5.73436 17.5546L10.3021 14.8451Z"
        fill={stone}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8715 0.151697V15.1842L10.3021 14.8451L5.73436 17.5546C4.89786 18.0531 3.87424 17.3162 4.09437 16.3841L5.3051 11.2686L1.26567 7.82221C0.528228 7.19361 0.924467 6.00145 1.89305 5.92559L7.20925 5.48124L9.2895 0.658399C9.56643 0.00877729 10.3376 -0.160123 10.8715 0.151697Z"
        fill={pathFill}
      />
    </svg>
  );
};

const IconFavorable = ({ fill = "none", height = "24", width = "25", pathFill = darkPear, viewBox = "0 0 25 24" }) => {
  return (
    <svg fill={fill} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      <circle cx="12.5" cy="12" r="12" fill={lightPear} />
      <path
        d="M11.634 7.50049C12.0189 6.83382 12.9811 6.83382 13.366 7.50049L16.8301 13.5005C17.215 14.1672 16.7339 15.0005 15.9641 15.0005L9.0359 15.0005C8.2661 15.0005 7.78497 14.1672 8.16987 13.5005L11.634 7.50049Z"
        fill={pathFill}
      />
    </svg>
  );
};

const IconCritical = ({
  fill = "none",
  height = "24",
  width = "25",
  pathFill = poisonCherry,
  viewBox = "0 0 25 24",
}) => {
  return (
    <svg fill={fill} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      <circle cx="12.5" cy="12" r="12" fill={lightCherry} />
      <path
        d="M13.366 16.5005C12.9811 17.1672 12.0189 17.1672 11.634 16.5005L8.16987 10.5005C7.78497 9.83382 8.2661 9.00049 9.0359 9.00049L15.9641 9.00049C16.7339 9.00049 17.215 9.83382 16.8301 10.5005L13.366 16.5005Z"
        fill={pathFill}
      />
    </svg>
  );
};

const IconErrorData = ({
  fill = "none",
  height = "24",
  width = "24",
  pathFill = poisonCherry,
  viewBox = "0 0 24 24",
}) => {
  return (
    <svg fill={fill} height={height} viewBox={viewBox} width={width} xmlns={xmlnsUrl}>
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 13C11.45 13 11 12.55 11 12V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V12C13 12.55 12.55 13 12 13ZM13 17H11V15H13V17Z"
        fill={pathFill}
      />
    </svg>
  );
};

const IconVendorAlternateError = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns={xmlnsUrl}>
      <rect x="17" y="17" width="14" height="22" rx="1" fill="#FFCAB2" />
      <path
        d="M1 31C1 30.4477 1.44772 30 2 30H14C14.5523 30 15 30.4477 15 31V38C15 38.5523 14.5523 39 14 39H2C1.44772 39 1 38.5523 1 38V31Z"
        fill="#DDDDDD"
      />
      <path
        d="M33 25C33 24.4477 33.4477 24 34 24H46C46.5523 24 47 24.4477 47 25V38C47 38.5523 46.5523 39 46 39H34C33.4477 39 33 38.5523 33 38V25Z"
        fill="#DDDDDD"
      />
      <path
        d="M23.1221 1.60978C23.5011 0.914843 24.4989 0.914845 24.8779 1.60979L26.2649 4.15302C26.4088 4.41675 26.6635 4.60185 26.9588 4.65714L29.8062 5.19036C30.5842 5.33606 30.8926 6.28508 30.3487 6.86027L28.3586 8.9653C28.1522 9.18358 28.0549 9.48308 28.0936 9.78098L28.4663 12.6538C28.5682 13.4387 27.7609 14.0253 27.0458 13.6858L24.4288 12.4436C24.1575 12.3147 23.8425 12.3147 23.5712 12.4436L20.9542 13.6858C20.2391 14.0253 19.4318 13.4387 19.5337 12.6538L19.9064 9.78098C19.9451 9.48308 19.8478 9.18358 19.6414 8.96529L17.6513 6.86027C17.1074 6.28507 17.4158 5.33606 18.1938 5.19036L21.0412 4.65714C21.3365 4.60185 21.5912 4.41675 21.7351 4.15302L23.1221 1.60978Z"
        fill="#CC4308"
      />
    </svg>
  );
};

export {
  IconEmptyStar,
  IconFullStar,
  IconHalfStar,
  IconFavorable,
  IconCritical,
  IconErrorData,
  IconVendorAlternateError,
};
