import { Collapse } from "@material-ui/core";
import classNames from "classnames";
import { GcomDocType, useCaptureEventsV2 } from "gx-npm-lib";
import { MiniButton, TypographyComponent } from "gx-npm-ui";
import { Fragment, MouseEvent, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ClientEvent } from "../../../app.constants";
import { ProductOverviewContext } from "../../../context/product-overview.context";
import ExpandCollapseIcon from "./expand-collapse.icon";
import FeatureAndRank from "./feature-and-rank/feature-and-rank.component";
import styles from "./mq-template-v2.styles.module.scss";

const MIN_NUMBER_OF_FEATURES_TO_SHOW = 5;
const SESSION_PRODUCT_PROFILE_FEATURES_EXPANDED_KEY = "productProfileFeaturesListExpanded";

const MqTemplateV3Component = () => {
  const { t } = useTranslation();
  const {
    featsAndFuncs,
    isFromProductProfile,
    productCount,
    productId,
    templateId,
    productName,
    initProdId,
    initId,
    gcomResearchDocType,
    gcomResId,
  } = useContext(ProductOverviewContext);
  const [isExpanded, setIsExpanded] = useState(false);
  const captureEvents = useCaptureEventsV2();

  useEffect(() => {
    if (isFromProductProfile || productId <= 0 || templateId <= 0) {
      return;
    }
    const keyName = `${SESSION_PRODUCT_PROFILE_FEATURES_EXPANDED_KEY}-${templateId}-${productId}`;
    const sessionStorageExpanded = sessionStorage.getItem(keyName);
    setIsExpanded(sessionStorageExpanded === "true");
  }, [isFromProductProfile, productId, templateId]);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
    if (isFromProductProfile || productId <= 0 || templateId <= 0) {
      return;
    }
    const keyName = `${SESSION_PRODUCT_PROFILE_FEATURES_EXPANDED_KEY}-${templateId}-${productId}`;
    sessionStorage.setItem(keyName, (!isExpanded).toString());
  };

  const handlePoweredByLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    let eventType;
    let metaData;

    if (!isFromProductProfile && gcomResearchDocType === GcomDocType.EMERGING_MARKET_QUAD) {
      eventType = ClientEvent.PRODUCT_PROFILE_POWERED_BY_EMERGING_MARKET_QUADRANT_LINK_CLICKED;
      metaData = { productId: productId.toString(), productName: productName };
    } else if (isFromProductProfile && gcomResearchDocType === GcomDocType.EMERGING_MARKET_QUAD) {
      eventType = ClientEvent.INITIATIVE_PRODUCT_PROFILE_POWERED_BY_EMERGING_MARKET_QUADRANT_LINK_CLICKED;
      metaData = { initiativeId: initId, initProductId: initProdId, productName: productName };
    } else if (!isFromProductProfile && gcomResearchDocType === GcomDocType.MAGIC_QUAD) {
      eventType = ClientEvent.PRODUCT_PROFILE_POWERED_BY_CRITICAL_CAPABILITIES_LINK_CLICKED;
      metaData = { productId: productId.toString(), productName: productName };
    } else {
      eventType = ClientEvent.INITIATIVE_PRODUCT_PROFILE_POWERED_BY_CRITICAL_CAPABILITIES_LINK_CLICKED;
      metaData = { initiativeId: initId, initProductId: initProdId, productName: productName };
    }

    captureEvents([{ eventType, metaData }]);
    window.open(`https://www.gartner.com/document/${gcomResId}?ref=buysmartresearch`, "_blank", "noopener");
  };

  return (
    <Fragment>
      <div className={styles.ffHeaderV2}>
        <TypographyComponent boldness="medium" color="carbon" rootClassName={styles.title} styling="p2">
          {gcomResearchDocType === GcomDocType.EMERGING_MARKET_QUAD ? (
            t("Assessment Categories")
          ) : (
            <>
              {t("Features and functionality")} ({featsAndFuncs.length})
            </>
          )}
        </TypographyComponent>

        {gcomResId !== -1 && (
          <div className={styles.poweredBy}>
            <TypographyComponent rootClassName={styles.poweredByText} styling="p5">
              {t("Powered by")}
            </TypographyComponent>

            <a
              className={styles.poweredByLink}
              href={`https://www.gartner.com/document/${gcomResId}?ref=buysmartresearch`}
              onClick={handlePoweredByLinkClick}
            >
              <TypographyComponent
                boldness="medium"
                color="defaultCta"
                rootClassName={styles.poweredByLinkText}
                styling="p5"
              >
                {gcomResearchDocType === GcomDocType.EMERGING_MARKET_QUAD
                  ? t("Gartner Emerging Market Quadrant")
                  : t("Gartner Critical Capabilities")}
              </TypographyComponent>
            </a>
          </div>
        )}
      </div>
      <div className={styles.featureListColumnsV2}>
        <TypographyComponent boldness="medium" color="coal" styling="p4">
          {gcomResearchDocType === GcomDocType.EMERGING_MARKET_QUAD ? t("Categories") : t("Capabilities")}
        </TypographyComponent>
        <div className={styles.productRanking}>
          <TypographyComponent boldness={"medium"} color={"coal"} styling={"p4"}>
            {t("Vendor rank")}
            <TypographyComponent color={"iron"} element={"span"}>{` (${t(
              "out of"
            )} ${productCount})`}</TypographyComponent>
          </TypographyComponent>
        </div>
      </div>
      <div role="list">
        {featsAndFuncs.slice(0, MIN_NUMBER_OF_FEATURES_TO_SHOW).map((item) => (
          <FeatureAndRank
            key={item.name}
            description={item.description}
            name={item.name}
            rank={item.rank}
            topProducts={item.topProducts}
          />
        ))}
      </div>
      <Collapse in={isExpanded}>
        <div role="list">
          {featsAndFuncs.slice(MIN_NUMBER_OF_FEATURES_TO_SHOW).map((item) => (
            <FeatureAndRank
              key={item.name}
              description={item.description}
              name={item.name}
              rank={item.rank}
              topProducts={item.topProducts}
            />
          ))}
        </div>
      </Collapse>
      <MiniButton onClick={handleClick} rootClassName={styles.expandCollapseBtn}>
        <ExpandCollapseIcon rootClassName={classNames(styles.icon, isExpanded && styles.expanded)} />
        {isExpanded ? t("Show less features") : t("Show more features")}
      </MiniButton>
    </Fragment>
  );
};

export default MqTemplateV3Component;
