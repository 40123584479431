import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import ProductOverview from "./sections/product-overview.component";
import { productOverviewStyles as styles } from "./app.component.styles";
import { ProductOverviewContextProvider } from "./context/product-overview.context";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles(() => styles);
const App = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isFromProductProfile, setIsFromProductProfile] = useState(false);

  useEffect(() => {
    const { pathname } = window.location;
    setIsFromProductProfile(pathname.includes("profile"));
  }, []);

  return (
    <section
      aria-label={t("Product overview")}
      className={classNames(classes.container, isFromProductProfile && "product-profile")}
    >
      <ProductOverviewContextProvider>
        <ProductOverview />
      </ProductOverviewContextProvider>
    </section>
  );
};

export default App;
