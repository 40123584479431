import React, { Fragment } from "react";
import { ProductLogo, TypographyComponent } from "gx-npm-ui";
import NotEntitledSVG from "../../../assets/images/not-entitled.svg";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { notEntitledStyles as styles } from "./not-entitled.styles";
import AlertIcon from "./alert.icon";

const useStyles = makeStyles(() => styles);

type NotEntitledProps = {
  imageLoc: string;
  productName: string;
};

const NotEntitled: React.FC<NotEntitledProps> = ({ imageLoc, productName }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className={classes.productDescription}>
        <ProductLogo imageWidth="48px" imageHeight="48px" logo={imageLoc} name={productName} />
        <TypographyComponent boldness={"semi"} styling={"h3"}>
          {productName}
        </TypographyComponent>
      </div>
      <div className={classes.noFeatures}>
        <div className={classes.noFeaturesDesc}>
          <AlertIcon />
          <TypographyComponent boldness={"medium"} styling={"p3"} color={"coal"} rootClassName={classes.title}>
            {t(
              "This insight is only available for Gartner licensed users, to access features and functionality please contact your Gartner Account Manager."
            )}
          </TypographyComponent>
        </div>
        <img src={NotEntitledSVG} alt="Not entitled" />
      </div>
    </Fragment>
  );
};

export default NotEntitled;
