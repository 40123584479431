import { colorPalette } from "gx-npm-common-styles";

const ExpandCollapseIcon = ({ rootClassName = "" }: { rootClassName?: string }) => {
  return (
    <svg
      className={rootClassName}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M12.6748 14.5625L16.0333 11.204C16.4044 10.8329 16.4044 10.2329 16.0333 9.86185C15.6622 9.49076 15.0622 9.49076 14.6911 9.86185L12.0002 12.5459L9.31622 9.86185C8.94513 9.49076 8.34516 9.49076 7.97407 9.86185C7.60298 10.2329 7.60298 10.8329 7.97407 11.204L11.3317 14.5616C11.6971 14.9354 12.3052 14.9321 12.6748 14.5625Z"
        fill={colorPalette.interactions.blueBerry.hex}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM4.90244 12C4.90244 8.08013 8.08013 4.90244 12 4.90244C15.9199 4.90244 19.0976 8.08013 19.0976 12C19.0976 15.9199 15.9199 19.0976 12 19.0976C8.08012 19.0976 4.90244 15.9199 4.90244 12Z"
        fill={colorPalette.interactions.blueBerry.hex}
        fillRule="evenodd"
      />
    </svg>
  );
};

export default ExpandCollapseIcon;
