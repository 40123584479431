const AlertIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7626_15023)">
        <circle cx="12" cy="12" r="12" fill="#FFCAB2" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.25 5C11.5596 5 11 5.55964 11 6.25V13.75C11 14.4404 11.5596 15 12.25 15C12.9404 15 13.5 14.4404 13.5 13.75V6.25C13.5 5.55964 12.9404 5 12.25 5ZM12.25 19.5C12.9404 19.5 13.5 18.9404 13.5 18.25C13.5 17.5596 12.9404 17 12.25 17C11.5596 17 11 17.5596 11 18.25C11 18.9404 11.5596 19.5 12.25 19.5Z"
          fill="#CC4308"
        />
      </g>
      <defs>
        <clipPath id="clip0_7626_15023">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AlertIcon;
