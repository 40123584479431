import { colorPalette } from "gx-npm-common-styles";

const colorWhite = colorPalette.basic.white.hex;
const colorCarbon = colorPalette.neutrals.carbon.hex;

const featuresFunctionalityStyles = {
  root: {
    backgroundColor: colorWhite,
    padding: "32px",
    borderRadius: "8px",
    color: colorCarbon,
    marginBottom: "24px",
  },
};

export { featuresFunctionalityStyles };
