import { colorPalette, weightPalette } from "gx-npm-common-styles";

const colorCoal = colorPalette.neutrals.coal.hex;
const colorQuartz = colorPalette.neutrals.quartz.hex;
const colorTin = colorPalette.neutrals.tin.hex;
const colorSilver = colorPalette.neutrals.silver.hex;
const featureAndRankStyles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${colorSilver}`,
    height: "44px",
    "&:hover": {
      "& > $featureContainer, & > $rankContainer, & > $viewDetails": {
        background: colorQuartz,
        cursor: "pointer",
      },
    },
  },
  featureContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
    flexGrow: 1,
    paddingLeft: "8px",
    "&:hover": {
      "& > $viewDetails": {
        visibility: "visible" as const,
      },
    },
  },
  feature: {
    color: colorCoal,
  },
  viewDetails: {
    paddingRight: "34px",
    display: "flex",
    color: colorCoal,
    visibility: "hidden" as const,
  },
  rankContainer: {
    gap: "12px",
    width: "147px",
    justifyContent: "center",
    height: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: colorTin,
    "&.gx-medal-icon": {
      paddingLeft: "36px",
    },
  },
  rankText: {
    fontSize: "12px",
    paddingLeft: "1px",
    letterSpacing: "0.5px",
    fontWeight: weightPalette.regular.amount,
    fontVariationSettings: weightPalette.regular.variation,
  },
};

export { featureAndRankStyles };
