// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FZSS2v2MBRnLO_jBFZ1L{display:flex;align-items:center;padding:25px;background-color:var(--color-neutrals-tin);border-radius:4px;gap:20px}.M41IoT2FM_EDxHFopEZt{margin-bottom:16px}`, "",{"version":3,"sources":["webpack://./src/sections/features-functionality/no-features/no-features-v2.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,kBAAA,CACA,YAAA,CACA,0CAAA,CACA,iBAAA,CACA,QAAA,CAGF,sBACE,kBAAA","sourcesContent":[".noFeatures {\n  display: flex;\n  align-items: center;\n  padding: 25px;\n  background-color: var(--color-neutrals-tin);\n  border-radius: 4px;\n  gap: 20px;\n}\n\n.title {\n  margin-bottom: 16px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noFeatures": `FZSS2v2MBRnLO_jBFZ1L`,
	"title": `M41IoT2FM_EDxHFopEZt`
};
export default ___CSS_LOADER_EXPORT___;
