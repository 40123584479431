import { isValidDate } from "gx-npm-lib";
const formatDate = (date: string) => {
  let display = "";
  if (isValidDate(date)) {
    const day = "2-digit";
    const month = "short";
    const timeZone = "UTC";
    const year = "numeric";
    const options: Intl.DateTimeFormatOptions = { day, month, timeZone, year };
    display = new Date(date).toLocaleString("en-us", options);
  }
  return display;
};

export { formatDate };
