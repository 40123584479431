import React, { Fragment, useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { GartnerFooter, Loader, SnackbarBanner } from "gx-npm-ui";
import { getAsyncRequest, isValidResponse } from "gx-npm-lib";
import { ProductOverviewPayloadType } from "../types";
import { makeStyles } from "@material-ui/core";
import { productOverviewStyles as styles } from "./product-overview.styles";
import Reviews from "./reviews/reviews.component";
import MagicQuadrant from "./magic-quadrant/magic-quadrant.component";
import FeaturesFunctionality from "./features-functionality/features-functionality.component";
import MoreFromGartner from "./more-from-gartner/more-from-gartner.component";
import { VendorAlternatives } from "./vendor-alternatives/vendor-alternatives.component";
import { ProductOverviewContext } from "../context/product-overview.context";
import ProposalReview from "./proposal-review/proposal-review.component";

const useStyles = makeStyles(() => styles);

const ProductOverview: React.FC = () => {
  const {
    initId,
    initProdId,
    isEligibleProductPR,
    isError,
    isFromProductProfile,
    productId,
    productName,
    templateId,
    setAlternativeProducts,
    setFeatsAndFuncs,
    setGcomCcDocCd,
    setGcomCcResId,
    setHasMq,
    setHasReview,
    setHasSearchResults,
    setImageLoc,
    setIsEligibleProductPR,
    setIsError,
    setMq,
    setProductName,
    setProductCount,
    setProductUrl,
    setReview,
    setSearchResults,
    setTeamMembersWithPRAccess,
    setTemplateName,
  } = useContext(ProductOverviewContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let url = "";
    if (
      (templateId && !productId) ||
      (!templateId && productId) ||
      (initId && !initProdId) ||
      (!initId && initProdId)
    ) {
      return;
    }
    if (initId && initProdId) {
      url = `api/v2/initiatives/product-overview/${initId}/${initProdId}`;
    } else if (productId && templateId) {
      url = `api/v2/data/product-overview/${templateId}/${productId}`;
    } else {
      return;
    }

    (async () => {
      setIsLoading(true);
      const response = await getAsyncRequest(url);
      if (isValidResponse(response)) {
        const payload: ProductOverviewPayloadType = response.data.data;
        if (payload) {
          setAlternativeProducts(payload.productAlternatives);
          setFeatsAndFuncs(payload.featsAndFuncs);
          setGcomCcDocCd(payload.gcomCcDocCd);
          setGcomCcResId(payload.gcomCcResId);
          setHasMq(payload.hasMq);
          setHasReview(payload.hasReview);
          setHasSearchResults(payload.hasSearchResults);
          setImageLoc(payload.imageLoc);
          setIsEligibleProductPR(payload.isEligiblePR);
          setMq(payload.mq);
          setProductCount(payload.productCount);
          setProductName(payload.productName);
          setProductUrl(payload.productURL);
          setReview(payload.review);
          setSearchResults(payload.searchResults);
          setTeamMembersWithPRAccess(payload.teamMembersWithPRAccess);
          setTemplateName(payload.templateName);
        }
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    })();
  }, [
    initId,
    initProdId,
    isEligibleProductPR,
    templateId,
    productId,
    productName,
    setAlternativeProducts,
    setFeatsAndFuncs,
    setGcomCcDocCd,
    setGcomCcResId,
    setImageLoc,
    setIsEligibleProductPR,
    setIsError,
    setHasReview,
    setHasMq,
    setHasSearchResults,
    setMq,
    setProductName,
    setProductUrl,
    setProductCount,
    setReview,
    setSearchResults,
    setTeamMembersWithPRAccess,
    setTemplateName,
  ]);

  const classes = useStyles();
  return (
    <Fragment>
      {isLoading && <Loader />}
      {!isLoading && (
        <Fragment>
          <div className={classNames(classes.container, isFromProductProfile && "product-profile")}>
            <div className={classes.marketInfo}>
              <div className={classes.leftColumn}>
                <FeaturesFunctionality />
                <Reviews />
                <MoreFromGartner />
              </div>
              <div className={classes.rightColumn}>
                <MagicQuadrant />
                {isEligibleProductPR && <ProposalReview />}
                <VendorAlternatives />
              </div>
            </div>
          </div>
          <GartnerFooter />
        </Fragment>
      )}
      <SnackbarBanner isOpen={isError} setIsOpen={setIsError} type="ERROR" isDefaultErrorMessage={true} />
    </Fragment>
  );
};

export default ProductOverview;
