// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gh1ywnXOfsQj1QWVIz9n{margin-bottom:12px}.nNy57P8T49bbzt3VCGcV .qT7XiUlxCXWdeTCY9GVX{padding-bottom:40px;padding-inline-start:24px}.W0jqQ5eSFZjNm2SVDqIw{float:right}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/free-trial/free-trial-info-modal/free-trial-info-modal.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CAGF,4CACE,mBAAA,CACA,yBAAA,CAGF,sBACE,WAAA","sourcesContent":[".sentenceBottomBreak {\n  margin-bottom: 12px;\n}\n\n.listContainer .list {\n  padding-bottom: 40px;\n  padding-inline-start: 24px;\n}\n\n.footerButton {\n  float: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sentenceBottomBreak": `gh1ywnXOfsQj1QWVIz9n`,
	"listContainer": `nNy57P8T49bbzt3VCGcV`,
	"list": `qT7XiUlxCXWdeTCY9GVX`,
	"footerButton": `W0jqQ5eSFZjNm2SVDqIw`
};
export default ___CSS_LOADER_EXPORT___;
