import { colorPalette } from "gx-npm-common-styles";

const colorIron = colorPalette.neutrals.iron.hex;
const colorTin = colorPalette.neutrals.tin.hex;

const noFeaturesStyles = {
  productDescription: {
    display: "flex",
    alignItems: "center",
    gap: "23px",
    marginBottom: "32px",
  },
  noFeatures: {
    display: "flex",
    alignItems: "center",
    padding: "25px",
    backgroundColor: colorTin,
    borderRadius: "4px",
    gap: "20px",
  },
  title: {
    marginBottom: "16px",
  },
  subTitle: {
    color: colorIron,
  },
};

export { noFeaturesStyles };
