import { makeStyles } from "@material-ui/core";
import { useUserState } from "gx-npm-lib";
import { useContext } from "react";
import { ProductOverviewContext } from "../../context/product-overview.context";
import { featuresFunctionalityStyles as styles } from "./features-functionality.styles";
import NotEntitled from "./not-entitled/not-entitled.component";
import NoFeatures from "./no-features/no-features.component";
import MqTemplateV2Component from "./mq-template/mq-template-v2.component";

const useStyles = makeStyles(() => styles);
const FeaturesFunctionality = () => {
  const classes = useStyles();
  const { freeTrialRole, isEntitled, hasUserState } = useUserState();
  const { featsAndFuncs, gcomCcDocCd, imageLoc, location, productName } = useContext(ProductOverviewContext);
  const isOutsideInitiative = location.pathname.includes("s/market");
  const showNotEntitled = !isEntitled && !freeTrialRole && hasUserState && isOutsideInitiative;
  return (
    <div className={classes.root}>
      {showNotEntitled && <NotEntitled imageLoc={imageLoc} productName={productName} />}
      {!showNotEntitled && featsAndFuncs.length === 0 && <NoFeatures />}
      {gcomCcDocCd !== -1 && featsAndFuncs.length !== 0 && <MqTemplateV2Component />}
    </div>
  );
};

export default FeaturesFunctionality;
