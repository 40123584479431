import { colorPalette } from "gx-npm-common-styles";

const colorBlue = colorPalette.basic.primary.hex;
const colorCoal = colorPalette.neutrals.coal.hex;
const colorTin = colorPalette.neutrals.tin.hex;
const colorWhite = colorPalette.basic.white.hex;

const magicQuadrantStyles = {
  root: {
    borderTop: "12px solid var(--color-brand-sky)",
    alignSelf: "flex-start",
    backgroundColor: colorWhite,
    borderRadius: 8,
    color: colorCoal,
    padding: 32,
    position: "relative" as string as "relative",
  },
  noData: {
    color: colorCoal,
    marginTop: 16,
  },
  errorState: {
    display: "inline-flex",
    "& svg": {
      minHeight: 24,
      minWidth: 24,
    },
    "& p": {
      display: "inline",
      marginLeft: 10,
    },
  },
  section: {
    display: "flex",
    marginBottom: 24,
  },
  title: {
    marginLeft: 17,
    marginTop: 3,
  },
  qdContainer: {
    maxWidth: 174,
    display: "flex",
    flexWrap: "wrap" as string as "wrap",
    gap: 4,
  },
  qdSubtitle: {
    color: colorPalette.neutrals.iron.hex,
    marginBottom: 8,
  },
  qdInfo: {
    display: "flex",
    gap: 12,
    justifyContent: "space-between",
  },
  qdType: {
    marginTop: 8,
  },
  qdText: {
    color: colorBlue,
  },
  qdDesc: {
    color: colorCoal,
    marginTop: 32,
    marginBottom: 32,
    maxWidth: "600px",
  },
  qdBoxSelected: {
    alignItems: "center",
    background: colorBlue,
    color: colorWhite,
    display: "flex",
    fontSize: 12,
    height: 76,
    justifyContent: "center",
    letterSpacing: "0.35px",
    width: 76,
  },
  positionTextSelected: {
    color: colorWhite,
  },
  noMq: {
    borderRadius: 4,
    background: colorTin,
    display: "flex",
    alignItems: "center",
    flexDirection: "column" as string as "column",
    gap: 32,
    paddingBottom: 24,
    paddingTop: 24,
  },
};

export { magicQuadrantStyles };
