import { createContext, ReactNode, useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { ProductOverviewContextValue } from "./product-overview.types";
import { DEFAULT_PRODUCT_REVIEW } from "../app.constants";
import { GcomDocType, GartnerQuadrant } from "gx-npm-lib";
import {
  FeatAndFunc,
  MagicQuadrantType,
  ProductAlternative,
  ProductReviewType,
  SearchResultType,
  TeamMembersWithPRAccessType,
} from "../app.types";

const defaultProductReview = (): ProductReviewType => DEFAULT_PRODUCT_REVIEW;

const ProductOverviewContext = createContext<ProductOverviewContextValue>({} as ProductOverviewContextValue);
const ProductOverviewContextProvider = (props: { children: ReactNode | Array<ReactNode> }) => {
  const location = useLocation();
  const [alternativeProducts, setAlternativeProducts] = useState<ProductAlternative[]>([]);
  const [templateAlternativeProducts, setTemplateAlternativeProducts] = useState<ProductAlternative[]>([]);
  const [featsAndFuncs, setFeatsAndFuncs] = useState<FeatAndFunc[]>([]);
  const [gcomCcDocCd, setGcomCcDocCd] = useState<number>(-1);
  const [gcomCcResId, setGcomCcResId] = useState<number>(-1);
  const [gcomResId, setGcomResId] = useState<number>(-1);
  const [hasMq, setHasMq] = useState(false);
  const [hasReview, setHasReview] = useState(false);
  const [hasSearchResults, setHasSearchResults] = useState(false);
  const [imageLoc, setImageLoc] = useState<string>("");
  const [initId, setInitId] = useState("");
  const [initProdId, setInitProdId] = useState("");
  const [isEligibleProductPR, setIsEligibleProductPR] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isFromProductProfile, setIsFromProductProfile] = useState(false);
  const [mq, setMq] = useState<MagicQuadrantType>({ docURL: "", qdDesc: "", qdPosition: "" });
  const [productName, setProductName] = useState("");
  const [productUrl, setProductUrl] = useState("");
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [productId, setProductId] = useState<number>(0);
  const [productCount, setProductCount] = useState<number>(0);
  const [templateProductCount, setTemplateProductCount] = useState<number>(0);
  const [review, setReview] = useState<ProductReviewType>(defaultProductReview);
  const [searchResults, setSearchResults] = useState<SearchResultType[]>([]);
  const [teamMembersWithPRAccess, setTeamMembersWithPRAccess] = useState<TeamMembersWithPRAccessType[]>([]);
  const [templateId, setTemplateId] = useState(0);
  const [templateName, setTemplateName] = useState("");
  const [gcomMqResId, setGcomMqResId] = useState<number>(-1);
  const [gcomResearchDocType, setGcomResearchDocType] = useState<GcomDocType | string>("");
  const [position, setPosition] = useState<GartnerQuadrant>(GartnerQuadrant.NONE);
  const [description, setDescription] = useState<string>("");

  const contextValue: ProductOverviewContextValue = {
    alternativeProducts,
    featsAndFuncs,
    gcomCcDocCd,
    gcomCcResId,
    hasMq,
    hasReview,
    hasSearchResults,
    initProdId,
    initId,
    imageLoc,
    isEligibleProductPR,
    isError,
    isFromProductProfile,
    location,
    mq,
    productCount,
    productId,
    productName,
    productUrl,
    review,
    searchResults,
    teamMembersWithPRAccess,
    templateId,
    templateName,
    gcomMqResId,
    gcomResearchDocType,
    templateProductCount,
    templateAlternativeProducts,
    url,
    name,
    gcomResId,
    position,
    description,
    setTemplateProductCount,
    setTemplateAlternativeProducts,
    setUrl,
    setName,
    setAlternativeProducts,
    setFeatsAndFuncs,
    setGcomCcDocCd,
    setGcomCcResId,
    setHasMq,
    setHasReview,
    setHasSearchResults,
    setIsEligibleProductPR,
    setIsError,
    setIsFromProductProfile,
    setImageLoc,
    setInitId,
    setInitProdId,
    setMq,
    setProductCount,
    setProductId,
    setProductName,
    setProductUrl,
    setReview,
    setSearchResults,
    setTeamMembersWithPRAccess,
    setTemplateId,
    setTemplateName,
    setGcomMqResId,
    setGcomResearchDocType,
    setGcomResId,
    setPosition,
    setDescription,
  };

  const {
    productId: routeProductId,
    initProdId: routePid,
    initiativeId: routeId,
    templateId: routeTemplateId,
  } = useParams();

  useEffect(() => {
    setIsFromProductProfile(window.location.pathname.includes("profile"));
    setProductId(parseInt(routeProductId as string));
    setTemplateId(parseInt(routeTemplateId as string));
    setInitProdId(routePid as string);
    setInitId(routeId as string);
  }, [location, routeId, routePid, routeProductId, routeTemplateId]);

  return <ProductOverviewContext.Provider value={contextValue}>{props.children}</ProductOverviewContext.Provider>;
};

export { ProductOverviewContext, ProductOverviewContextProvider };
